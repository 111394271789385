<template>
  <div>
    <div
      v-if="totalsTimeEtaData.totalSeconds > 0"
      class="total-value"
    >
      Total:
      {{ formatTime }}
    </div>
    <div
      v-if="totalsTimeEtaData.totalEta > 0"
      class="total-value"
    >
      Total ETA:
      {{ formatTotalEta }}
    </div>
    <div
      v-for="(money, currency) in FormatTotalMoneyByCurrency"
      :key="currency.id"
      class="total-value-money"
    >
      <template
        v-if="money > 0 && user.consolidateAmounts === false"
      >
        {{ currency }}
        {{ money }}
      </template>
    </div>
    <div
      v-if="consolidateAmountsData"
      class="total-value-money"
    >
      {{ user.currency.symbol }}{{ consolidateAmountsData.toFixed(user.currency.decimalDigits) }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'TotalTimeMoney',

  computed: {
    ...mapGetters([
      'totalMoneyByCurrency',
      'user',
      'consolidateAmountsData',
      'totalsTimeEtaData',
      'filteredProjectsForCharts',
    ]),
    FormatTotalMoneyByCurrency() {
      const totalMoney = {};

      Object.entries(this.totalMoneyByCurrency).forEach(([key, value]) => {
        const project = this.filteredProjectsForCharts.find(pr => pr.currency.symbol === key);

        totalMoney[key] = value.toFixed(project.currency.decimalDigits);
      });

      return totalMoney;
    },
    formatTime() {
      return `${Math.floor(moment.duration(this.totalsTimeEtaData.totalSeconds, 'seconds').asHours())}h
      ${moment.duration(this.totalsTimeEtaData.totalSeconds, 'seconds').minutes()}m
      ${moment.duration(this.totalsTimeEtaData.totalSeconds, 'seconds').seconds()}s`;
    },
    formatTotalEta() {
      return `${Math.floor(moment.duration(this.totalsTimeEtaData.totalEta, 'seconds').asHours())}h
      ${moment.duration(this.totalsTimeEtaData.totalEta, 'seconds').minutes()}m`;
    },
  },
};
</script>

<style scoped>
  .total-value {
    font-size: 18px;
    color: #6A717C;
  }
  .total-value-money {
    color: #67c23b;
    font-size: 20px;
  }
</style>
